module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"schema":{"timeout":900000,"perPage":10,"requestConcurrency":5,"previewRequestConcurrency":2,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp"},"verbose":true,"debug":{"preview":true,"timeBuildSteps":true,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"url":"https://admin.vasion.com/graphql","protocol":"https","useACF":true,"includedRoutes":["**/categories","**/posts","**/pages","**/media","**/menus","**/tags","**/taxonomies","**/users","**/searchResults","**/whitepaper","**/case_study","**/video","**/resource_type","**/yoast"],"searchAndReplaceContentUrls":{"sourceUrl":"https://admin.vasion.com","replacementUrl":"https://vasion.com"},"catchLinks":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
